<div class="flex flex-col md:max-w-[30svg] max-w-2xl py-5 md:px-5 px-8 gap-3 overflow-y-auto">
    <div class="flex items-center justify-between w-full">
        <span class="font-bold mx-auto text-lg">¡Felicidades!</span>
        <button class="text-slate-400 hover:text-rose-500" (click)="dialogRef.close()" id="close-add-charge">
            <span class="sr-only">{{ 'CLOSE_STRING' | translate }}</span>
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
    <div class="flex flex-col items-center justify-center gap-3 w-full md:w-[75%] max-w-md mx-auto">
        <img class="rounded-lg shadow-md object-cover transition-transform duration-500 ease-in-out"
            src="/assets/images/05_trophy.webp" alt="Imagen Felicitación">
        <div class="mt-3">
            <p class="text-justify">¡Completaste el Tour de MiDespacho y lograste tu primer gran paso hacia la
                digitalización!</p>
            <p class="text-justify mt-2">
                Haz click aquí para descargar tu Certificado de Digitalización (también lo podrás ver y descargar desde
                tu Perfil).</p>
        </div>
        <button *ngIf="!(loadingCertificate|async)" (click)="downloadCertificate()"
            class="uisr-primary-button">Descargar</button>
        <button *ngIf="(loadingCertificate|async)" class="uisr-primary-button pointer-events-none"
            [disabled]="(loadingCertificate|async)">
            <i class="fa-spinner-third fa-solid animate-spin" style="font-size: 1rem;"></i>
        </button>
    </div>
</div>