import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { offset } from '@floating-ui/dom';
import { ShepherdService } from 'angular-shepherd';
import { DateTime } from 'luxon';
import * as Sp from 'shepherd.js';

@Injectable({
  providedIn: 'root',
})
export class AppTutorialService {
  private readonly tourService = inject(ShepherdService);
  private readonly router = inject(Router);

  private redirects = {
    '/law-firm/library/blog': { url: '/law-firm/library/post', params: {} },
    '/law-firm/files': { url: '/law-firm/files/add', params: {} },
    '/law-firm/library/post': { url: '/law-firm/library/my-posts', params: {} },
    '/search/b-search/results': {
      url: '/search/b-search/analysis/27706973',
      params: { index: 'cjf-documents' },
    },
  };

  startTour(steps: Sp.default.Step.StepOptions[], url?: string, name?: string) {
    if (this.tourService.isActive) {
      this.tourService.complete();
    }

    if (name) {
      this.tourService.tourName = name;
    } else {
      this.tourService.tourName = 'default';
    }

    const self = this;
    this.tourService.modal = true;
    this.tourService.defaultStepOptions = {
      classes: 'custom-tour',
      cancelIcon: {
        enabled: true,
        label: 'Salir',
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 6, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = Sp.default.activeTour?.getCurrentStep();
          const lastStep = Sp.default.activeTour?.steps.length;
          const currentStepElement = currentStep?.getElement();
          const footer = currentStepElement?.querySelector('.shepherd-footer');
          const progress = document.createElement('span');
          progress.className = 'shepherd-progress';
          if (currentStep && currentStepElement && Sp.default.activeTour) {
            const stepIndex =
              Sp.default.activeTour.steps.indexOf(currentStep) + 1;
            if (lastStep && stepIndex == lastStep && url) {
              const baseUrl = url.split('?')[0];
              const redirectUrl =
                self.redirects[baseUrl as keyof typeof self.redirects];
              if (redirectUrl) {
                this.getTour().complete();
                const params: any = { tour: true, ...redirectUrl.params };
                self.router.navigate([redirectUrl.url], {
                  queryParams: params,
                  queryParamsHandling: 'merge',
                });
              }
            }
            progress.innerText = `${stepIndex} de ${lastStep}`;
            footer?.insertBefore(
              progress,
              currentStepElement.querySelector('.shepherd-button:first-child')
            );
          }
        },
        complete() {
          console.log(self.tourService.tourName);
        }
      },
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 5,
      canClickTarget: false,
      scrollTo: true,
      arrow: true,
      buttons: [
        {
          secondary: true,
          text: 'Salir',
          action() {
            this.cancel();
            self.router.navigate([], {
              queryParams: { tour: null },
              queryParamsHandling: 'merge',
            });
          },
        },
        {
          text: '<i class="fa-solid fa-chevron-left"></i>',
          label: 'Anterior',
          action() {
            this.back();
          },
        },
        {
          text: '<i class="fa-solid fa-chevron-right"></i>',
          label: 'Siguiente',
          action() {
            this.next();
          },
        },
      ],
    };

    this.tourService.addSteps(steps);

    this.tourService.start();
  }

  shouldStartTour(storageItem: string, signupDate?: string) {
    if (signupDate) {
      const isFirstTime = localStorage.getItem(storageItem);
      const maxDate = DateTime.fromISO(signupDate.replace(' ', 'T')).plus({
        weeks: 1,
      });
      if (
        isFirstTime != 'false' && // Si aún no vé el tutorial
        DateTime.now() <= maxDate // Y si no ha pasado más de una semana desde el registro
      ) {
        // Debería iniciar el tutorial
        localStorage.setItem(storageItem, 'false');
        return true;
      }

      // No debería iniciar el tutorial
      return false;
    } else {
      return false;
    }
  }

  shouldStartTourFromDate(storageItem: string, maxDate: DateTime) {
      const isFirstTime = localStorage.getItem(storageItem);
      if (
        isFirstTime != 'false' && // Si aún no vé el tutorial
        DateTime.now() <= maxDate // Y si no ha pasado más de una semana desde el registro
      ) {
        // Debería iniciar el tutorial
        localStorage.setItem(storageItem, 'false');
        return true;
      }

      // No debería iniciar el tutorial
      return false;
  }

  isTutorialActive(name: string) {
    return this.tourService.tourName == name && this.tourService.isActive;
  }
}
