import { DialogConfig } from '@angular/cdk/dialog';

export const DEFAULT_DIALOG_OPTIONS: DialogConfig = {
  hasBackdrop: true,
  backdropClass: 'app-custom-backdrop',
  panelClass: ['app-custom-panel'],
  restoreFocus: false,
};

export const DEFAULT_SIDE_PANEL_OPTIONS: DialogConfig = {
  hasBackdrop: true,
  backdropClass: 'app-custom-backdrop',
  panelClass: [
    'app-side-panel',
    'animate__animated',
    'animate__slideInLeft',
    'animate__faster',
    'relative',
    'max-w-96',
    'md:max-w-[48rem]',
    'w-[95svw]',
    'h-[100svh]',
  ],
};

export const DEFAULT_PREVIEW_OPTIONS: DialogConfig = {
  hasBackdrop: false,
  panelClass: ['contents'],
};

export const DEFAULT_DROPDOWN_OPTIONS: DialogConfig = {
  panelClass: [
    'animate__animated',
    'animate__fadeIn',
    'animate__faster',
    'uisr-dropdown-menu',
  ],
  hasBackdrop: true,
  backdropClass: 'bg-transparent',
};
