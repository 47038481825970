<div class="grid min-h-[100svh] max-h-[100svh] overflow-hidden bg-slate-100 text-slate-600 w-svw flex-1 grid-rows-[1fr]">
    <div class="flex w-[100svw] min-h-0">
        <app-sidebar></app-sidebar>
        <div class="relative flex flex-col grow overflow-hidden transition-colors duration-200">
            <app-header (openSidebarRequest)="openSidebar()"></app-header>
            <div class="relative overflow-y-auto overflow-x-hidden flex flex-col grow" cdkScrollable>
                <router-outlet></router-outlet>
            </div>
            <app-assistant-chat></app-assistant-chat>
        </div>
    </div>

</div>