import {
  FormControlConfig,
  uisrFields,
} from 'src/app/core/interfaces/form-control-config.interface';

export const FILE_TABS = [
  {
    label: 'Información',
    action: 'information',
  },
  {
    label: 'Expediente',
    action: 'activity',
  },
  {
    label: 'Documentos',
    action: 'documents',
  },
  {
    label: 'Legislación',
    action: 'collections',
  },
  {
    label: 'Notas',
    action: 'notes',
  },
];

export const CLIENT_TABS = [
  {
    label: 'Información',
    action: 'information',
    permission: 'clientes:leer',
  },
  {
    label: 'Asuntos',
    action: 'files',
    permission: 'expedientes:leer',
  },
  {
    label: 'Facturas',
    action: 'invoices',
    permission: 'cobranzas:leer',
  },
  {
    label: 'Documentos',
    action: 'documents',
    permission: 'clientes:leer',
  },
  {
    label: 'Notas',
    action: 'notes',
    permission: 'clientes:leer',
  },
];

export const FIELDS_ALL_TEMPLATE: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Descripción del Caso',
    name: 'descripcionDelCaso',
    disabled: true,
    placeholder: 'Descripción del Caso',
    description: 'Describe el caso',
    order: 1,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Número de Asunto',
    name: 'numerodeexpediente',
    disabled: true,
    placeholder: 'Número del asunto',
    description: 'Número de asunto dentro del tribunal',
    order: 2,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Estado',
    name: 'estadoTribunal',
    disabled: true,
    placeholder: 'Estado',
    description: '¿En qué estado se lleva?',
    order: 14,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Etiquetas',
    name: 'tags',
    placeholder: 'Ingresa o agrega etiquetas',
    description:
      'Etiquetas personalizadas para identificar el caso (puedes agregar más de uno)',
    order: 8,
    validators: [],
  },
  {
    type: 'text',
    label: 'Tipo de órgano',
    name: 'tipoOrgano',
    disabled: true,
    placeholder: 'Tipo de órgano',
    description: '¿En qué tipo de órgano se lleva?',
    order: 3,
    validators: [],
  },
  {
    type: 'text',
    label: 'Órgano Jurisdiccional',
    name: 'tribunal',
    disabled: true,
    placeholder: 'Tribunal',
    description: '¿En qué tribunal se lleva?',
    order: 4,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Actor / Quejoso',
    name: 'actorQuejoso',
    disabled: true,
    placeholder: 'Actor / Quejoso',
    description: ' Quien es el actor o quejoso (puedes agregar más de uno)',
    order: 5,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Demandado / Autoridad Responsable',
    name: 'demandadoAutoridadResponsable',
    disabled: true,
    placeholder: 'Demandado / Autoridad Responsable',
    description:
      ' Quien es el demandado o autoridad responsable (puedes agregar más de uno)',
    order: 6,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Equipo Encargado',
    name: 'equipoEncargado',
    disabled: true,
    placeholder: 'Equipo Encargado',
    description: ' Quien es el equipo encargado (puedes agregar más de uno)',
    order: 7,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Clientes',
    name: 'clientes',
    disabled: true,
    placeholder: 'Clientes del despacho',
    description:
      ' Quienes son los clientes que deseas que participen (puedes agregar más de uno)',
    order: 8,
    validators: [],
  },

];

export const FIELDS_CREATE_TEMPLATE: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Estado',
    name: 'estadoTribunal',
    disabled: true,
    placeholder: 'Estado',
    description: '¿En qué estado se lleva?',
    order: 3,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Etiquetas',
    name: 'tags',
    placeholder: 'Ingresa o agrega etiquetas',
    description:
      'Etiquetas personalizadas para identificar el caso (puedes agregar más de uno)',
    order: 8,
    validators: [],
  },
  {
    type: 'text',
    label: 'Observaciones',
    name: 'observaciones',
    disabled: true,
    placeholder: 'Observaciones del caso',
    description: 'Una observación detalla del caso',
    order: 0,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Acto Reclamado',
    name: 'actoReclamado',
    disabled: true,
    placeholder: 'Acto Reclamado',
    description: '¿Cuál es el acto cuestionado? Describe brevemente.',
    order: 0,
    validators: [],
  },
];

export const FIELD_DROP_DOWN: FormControlConfig[] = [
  // {
  //   type: 'text',
  //   label: 'Tipo de órgano',
  //   name: 'tipoOrgano',
  //   disabled: true,
  //   placeholder: 'Tipo de órgano',
  //   description: '¿En qué tipo de órgano se lleva?',
  //   order: 3,
  //   validators: [],
  // },
  {
    type: 'text',
    label: 'Materia',
    name: 'materia',
    disabled: true,
    placeholder: 'Materia del caso',
    description: '¿Cuál es la materia principal?',
    order: 10,
    validators: [],
  },
  {
    type: 'text',
    label: 'Sentencia',
    name: 'sentencia',
    disabled: true,
    placeholder: 'Sentencia',
    description: '¿Cuál fue el veredicto? Breve descripción aquí?',
    order: 11,
    validators: [],
  },
  {
    type: 'text',
    label: 'Tipo de caso',
    name: 'tipoCaso',
    disabled: true,
    placeholder: 'Tipo de caso',
    description: '¿Qué tipo de caso es?',
    order: 12,
    validators: [],
  },
  {
    type: 'text',
    label: 'Caso(s) ligado(s)',
    name: 'casosLigados',
    disabled: true,
    placeholder: 'Casos ligados al asunto',
    description: ' Casos ligados al asunto (puedes agregar más de uno)',
    order: 13,
    validators: [],
  },
  {
    type: 'text',
    label: 'Estatus',
    name: 'estatus',
    disabled: true,
    placeholder: 'Estatus',
    description: '¿En qué etapa está el caso?',
    order: 14,
    validators: [],
  },
];

export const FIELDS_UISR: uisrFields = {
  '1': [
    {
      type: 'text',
      label: 'Número de Asunto',
      name: 'numerodeexpediente',
      disabled: true,
      placeholder: 'Número de Asunto',
      description: 'Número de asunto dentro del tribunal',
      order: 1,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    // {
    //   type: 'text',
    //   label: 'Estado Tribunal',
    //   name: 'estadoTribunal',
    //   disabled: true,
    //   placeholder: 'Estado Tribunal',
    //   description: '¿En qué estado se lleva?',
    //   order: 3,
    //   validators: [
    //     {
    //       type: 'required',
    //     },
    //   ],
    // },
    {
      type: 'text',
      label: 'Juzgado / Tribunal',
      name: 'tribunal',
      disabled: true,
      placeholder: 'Tribunal',
      description: 'En qué tribunal se encuentra',
      order: 5,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Actor',
      name: 'actorQuejoso',
      disabled: true,
      placeholder: 'Actor',
      description: ' Quien es el actor (puedes agregar más de uno)',
      order: 2,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Demandado',
      name: 'demandadoAutoridadResponsable',
      disabled: true,
      placeholder: 'Demandado ',
      description: ' Quien es el demandado (puedes agregar más de uno)',
      order: 3,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Descripción del Caso',
      name: 'descripcionDelCaso',
      disabled: true,
      placeholder: 'Descripción del Caso',
      description: 'Describe el caso',
      order: 9,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Equipo Encargado',
      name: 'equipoEncargado',
      disabled: true,
      placeholder: 'Equipo Encargado',
      description: ' Quien es el equipo encargado (puedes agregar más de uno)',
      order: 10,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    // {
    //   type: 'text',
    //   label: 'Roles',
    //   name: 'roles',
    //   disabled: true,
    //   placeholder: 'Roles que participan en el caso',
    //   description:
    //     ' Quienes son los roles que deseas que participen (puedes agregar más de uno)',
    //   order: 10,
    //   validators: [],
    // },
    {
      type: 'text',
      label: 'Clientes',
      name: 'clientes',
      disabled: true,
      placeholder: 'Clientes del despacho',
      description:
        ' Quienes son los clientes que deseas que participen (puedes agregar más de uno)',
      order: 3,
      validators: [],
    },
  ],
  '2': [
    {
      type: 'text',
      label: 'Número de Asunto',
      name: 'numerodeexpediente',
      disabled: true,
      placeholder: 'Número de asunto',
      description: 'Número de asunto dentro del tribunal',
      order: 1,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    // {
    //   type: 'text',
    //   label: 'Estado Tribunal',
    //   name: 'estadoTribunal',
    //   disabled: true,
    //   placeholder: 'Estado Tribunal',
    //   description: '¿En qué estado se lleva?',
    //   order: 6,
    //   validators: [
    //     {
    //       type: 'required',
    //     },
    //   ],
    // },
    {
      type: 'text',
      label: 'Juzgado / Tribunal',
      name: 'tribunal',
      disabled: true,
      placeholder: 'Tribunal',
      description: 'En qué tribunal se encuentra',
      order: 7,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Descripción del Caso',
      name: 'descripcionDelCaso',
      disabled: true,
      placeholder: 'Descripción del Caso',
      description: 'Describe el caso',
      order: 2,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Autoridad Responsable',
      name: 'demandadoAutoridadResponsable',
      disabled: true,
      placeholder: 'Autoridad Responsable',
      description:
        'Quien es la Autoridad Responsable (puedes agregar más de uno)',
      order: 4,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Quejoso',
      name: 'actorQuejoso',
      disabled: true,
      placeholder: 'Quejoso',
      description: ' Quien es el Quejoso (puedes agregar más de uno)',
      order: 3,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    {
      type: 'text',
      label: 'Equipo Encargado',
      name: 'equipoEncargado',
      disabled: true,
      placeholder: 'Equipo Encargado',
      description: ' Quien es el equipo encargado (puedes agregar más de uno)',
      order: 10,
      validators: [
        {
          type: 'required',
        },
      ],
    },
    // {
    //   type: 'text',
    //   label: 'Roles',
    //   name: 'roles',
    //   disabled: true,
    //   placeholder: 'Roles que participan en el caso',
    //   description:
    //     ' Quienes son los roles que deseas que participen (puedes agregar más de uno)',
    //   order: 11,
    //   validators: [],
    // },
    {
      type: 'text',
      label: 'Clientes',
      name: 'clientes',
      disabled: true,
      placeholder: 'Clientes del despacho',
      description:
        ' Quienes son los clientes que deseas que participen (puedes agregar más de uno)',
      order: 5,
      validators: [],
    },
  ],
};

export const KTD_GRID_CUSTOM_TRANSITIONS = [
  {
    name: 'ease',
    value: 'transform 500ms ease, width 500ms ease, height 500ms ease',
  },
  {
    name: 'ease-out',
    value:
      'transform 500ms ease-out, width 500ms ease-out, height 500ms ease-out',
  },
  {
    name: 'linear',
    value: 'transform 500ms linear, width 500ms linear, height 500ms linear',
  },
  {
    name: 'overflowing',
    value:
      'transform 500ms cubic-bezier(.28,.49,.79,1.35), width 500ms cubic-bezier(.28,.49,.79,1.35), height 500ms cubic-bezier(.28,.49,.79,1.35)',
  },
  {
    name: 'fast',
    value: 'transform 200ms ease, width 200ms linear, height 200ms linear',
  },
  {
    name: 'slow-motion',
    value: 'transform 1000ms linear, width 1000ms linear, height 1000ms linear',
  },
  { name: 'transform-only', value: 'transform 500ms ease' },
];

export const NOTE_COLOR_OPTIONS = [
  { value: 'bg-blue-200' },
  { value: 'bg-green-200' },
  { value: 'bg-amber-200' },
  { value: 'bg-pink-200' },
];

export const CK_EDITOR_NOTES_CONFIG = {
  language: 'es',
  toolbar: [
    'undo',
    'redo',
    '|',
    'bold',
    'italic',
    '|',
    'link',
    'insertTable',
    'blockQuote',
    '|',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent',
  ],
};

export const ADD_REMINDER_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Título',
    name: 'title',
    validators: [
      {
        type: 'required',
      },
    ],
    order: 0,
  },
  {
    type: 'text',
    label: 'Descripción',
    name: 'description',
    validators: [
      {
        type: 'required',
      },
    ],
    order: 1,
  },
  {
    type: 'text',
    label: '',
    name: 'dueDate',
    placeholder: 'Selecciona una fecha.',
    validators: [
      {
        type: 'required',
      },
    ],
    order: 2,
  },
];

export const INITIAL_CONTROLS_TEMPLATE: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Descripción del Caso',
    name: 'descripcionDelCaso',
    disabled: true,
    placeholder: 'Descripción del Caso',
    description: 'Describe el caso',
    order: 1,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Número de Asunto',
    name: 'numerodeexpediente',
    disabled: true,
    placeholder: 'Número del asunto',
    description: 'Número de asunto dentro del tribunal',
    order: 2,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'select',
    label: 'Tipo de órgano',
    name: 'tipoOrgano',
    disabled: true,
    placeholder: 'Tipo de órgano',
    description: '¿En qué tipo de órgano se lleva?',
    order: 3,
    validators: [],
  },
  {
    type: 'select',
    label: 'Órgano Jurisdiccional',
    name: 'tribunal',
    disabled: true,
    placeholder: 'Tribunal',
    description: '¿En qué tribunal se lleva?',
    order: 4,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Actor / Quejoso',
    name: 'actorQuejoso',
    disabled: true,
    placeholder: 'Actor / Quejoso',
    description: ' Quien es el actor o quejoso (puedes agregar más de uno)',
    order: 5,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Demandado / Autoridad Responsable',
    name: 'demandadoAutoridadResponsable',
    disabled: true,
    placeholder: 'Demandado / Autoridad Responsable',
    description:
      ' Quien es el demandado o autoridad responsable (puedes agregar más de uno)',
    order: 6,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Equipo Encargado',
    name: 'equipoEncargado',
    disabled: true,
    placeholder: 'Equipo Encargado',
    description: ' Quien es el equipo encargado (puedes agregar más de uno)',
    order: 7,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Clientes',
    name: 'clientes',
    disabled: true,
    placeholder: 'Clientes del despacho',
    description:
      ' Quienes son los clientes que deseas que participen (puedes agregar más de uno)',
    order: 8,
    validators: [],
  },
  {
    type: 'select',
    label: 'Materia',
    name: 'materia',
    disabled: true,
    placeholder: 'Materia del caso',
    description: '¿Cuál es la materia principal?',
    order: 9,
    validators: [],
  },
  {
    type: 'tag',
    label: 'Sentencia',
    name: 'sentencia',
    disabled: true,
    placeholder: 'Sentencia',
    description: '¿Cuál fue el veredicto? Breve descripción aquí?',
    order: 10,
    validators: [],
  },
  {
    type: 'text',
    label: 'Tipo de caso',
    name: 'tipoCaso',
    disabled: true,
    placeholder: 'Tipo de caso',
    description: '¿Qué tipo de caso es?',
    order: 11,
    validators: [],
  },
  {
    type: 'tag',
    label: 'Caso(s) ligado(s)',
    name: 'casosLigados',
    disabled: true,
    placeholder: 'Casos ligados al asunto',
    description: ' Casos ligados al asunto (puedes agregar más de uno)',
    order: 12,
    validators: [],
  },
  {
    type: 'select',
    label: 'Estatus',
    name: 'estatus',
    disabled: true,
    placeholder: 'Estatus',
    description: '¿En qué etapa está el caso?',
    order: 13,
    validators: [],
  },
  {
    type: 'text',
    label: 'Observaciones',
    name: 'observaciones',
    disabled: true,
    placeholder: 'Observaciones del caso',
    description: 'Una observacion detalla del caso',
    order: 15,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Acto Reclamado',
    name: 'actoReclamado',
    disabled: true,
    placeholder: 'Acto Reclamado',
    description: '¿Cuál es el acto cuestionado? Describe brevemente.',
    order: 16,
    validators: [],
  },
  {
    type: 'select',
    label: 'Estado',
    name: 'estadoTribunal',
    disabled: false,
    placeholder: 'Estado',
    description: '¿En qué estado se lleva?',
    order: 3,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Etiquetas',
    name: 'tags',
    placeholder: 'Ingresa o agrega etiquetas',
    description:
      'Etiquetas personalizadas para identificar el caso (puedes agregar más de uno)',
    order: 8,
    validators: [],
  },

];
 

export const INITIAL_MINIMUM_CONTROLS_TEMPLATE: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Número de Asunto',
    name: 'numerodeexpediente',
    disabled: false,
    placeholder: 'Número del asunto',
    description: 'Número de asunto dentro del tribunal',
    order: 2,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Equipo Encargado',
    name: 'equipoEncargado',
    disabled: false,
    placeholder: 'Equipo Encargado',
    description: ' Quien es el equipo encargado (puedes agregar más de uno)',
    order: 4,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'select',
    label: 'Estatus',
    name: 'estatus',
    disabled: false,
    placeholder: 'Estatus',
    description: '¿En qué etapa está el caso?',
    order: 6,
    validators: [
      {
        type: 'required',
      }
    ],
  },
];

export const FIELDS_TITLE_TEMPLATE: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Título del Caso',
    name: 'tituloDelCaso',
    disabled: false,
    placeholder: 'Título del Caso',
    description: '¿Cuál es el título del caso?',
    order: 1,
    optional: true,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Actor / Quejoso',
    name: 'actorQuejoso',
    disabled: false,
    placeholder: 'Actor / Quejoso',
    description: ' Quien es el actor o quejoso (puedes agregar más de uno)',
    order: 5,
    optional: true,
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'tag',
    label: 'Demandado / Autoridad Responsable',
    name: 'demandadoAutoridadResponsable',
    disabled: false,
    placeholder: 'Demandado / Autoridad Responsable',
    description:
      ' Quien es el demandado o autoridad responsable (puedes agregar más de uno)',
    order: 6,
    optional: true,
    validators: [
      {
        type: 'required',
      },
    ],
  },
];
