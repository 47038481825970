import { BehaviorSubject, defer, finalize, MonoTypeOperatorFunction, Observable } from 'rxjs';

// Mapa para almacenar el conteo de observables activos por modelo
const loadingCounters = new Map<BehaviorSubject<boolean>, number>();

export function loadingState<T>(model: BehaviorSubject<boolean>): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> => defer((): Observable<T> => {
        // Incrementa el contador al suscribirse
        const currentCount = loadingCounters.get(model) || 0;
        loadingCounters.set(model, currentCount + 1);

        // Emite true si es la primera suscripción activa
        if (currentCount === 0) {
            model.next(true);
        }

        return source.pipe(
            finalize(() => {
                // Decrementa el contador al finalizar
                const currentCount = loadingCounters.get(model) || 1;
                if (currentCount <= 1) {
                    loadingCounters.delete(model);
                    model.next(false);
                } else {
                    loadingCounters.set(model, currentCount - 1);
                }
            })
        );
    });
}