import { FormControlConfig } from 'src/app/core/interfaces/form-control-config.interface';

export const REGISTRATION_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Nombre de Usuario',
    name: 'nickname',
    placeholder: 'Nombre de Usuario',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Nombre',
    name: 'first_name',
    placeholder: 'Nombre',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Apellido',
    name: 'last_name',
    placeholder: 'Apellido',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'email',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
  {
    type: 'password',
    label: 'Contraseña',
    name: 'password',
    placeholder: 'Contraseña',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'password',
    label: 'Confirmar Contraseña',
    name: 'passwordConf',
    placeholder: 'Confirmar Contraseña',
    validators: [
      {
        type: 'required',
      },
     
    ],
  },
];

export const LOGIN_CONTROLS: FormControlConfig[] = [
  {
    type: 'email',
    label: 'Email',
    name: 'user',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'password',
    label: 'Contraseña',
    name: 'password',
    placeholder: 'Contraseña',
    validators: [
      {
        type: 'required',
      },
    ],
  },
];

export const PASSWORD_RECOVERY_CONTROLS: FormControlConfig[] = [
  {
    type: 'email',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
];

export const PASSWORD_CHANGE_CONTROLS: FormControlConfig[] = [
  {
    type: 'password',
    label: 'Contraseña',
    name: 'password',
    placeholder: 'Contraseña',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'pattern',
        value: /^(.{8,})$/,
      },
      {
        type: 'pattern',
        value: /[A-Z]/,
       
      },
      {
        type: 'pattern',
        value: /[a-z]/,
     
      },
      {
        type: 'pattern',
        value: /[0-9]/,
      
      },
      {
        type: 'pattern',
        value: /[~`!@#$%^&*()_\-\+={[}\]\|:;"'<,>\.?\/]/,
       
      },
    ],
  },
  {
    type: 'password',
    label: 'Confirmar Contraseña',
    name: 'passwordConf',
    placeholder: 'Confirmar Contraseña',
    validators: [
      {
        type: 'required',
      },
    
    ],
  },
];



export const EMAIL_CHANGE_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
  {
    type: 'text',
    label: 'Confirmar Email',
    name: 'emailConf',
    placeholder: 'Confirmar Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
];

export const PRE_SIGNUP_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
  {
    type: 'text',
    label: 'Nombre',
    name: 'names',
    placeholder: 'Nombre',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'pattern',
        value: /^[a-zA-Z\s]*$/,
      }
    ],
  },
  {
    type: 'text',
    label: 'Nombre del Despacho',
    name: 'name_dispatch',
    placeholder: 'Nombre del Despacho',
  },
  {
    type: 'text',
    label: 'Teléfono',
    name: 'phone_number',
    placeholder: 'Teléfono',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'maxLength',
        value: '15'
      },
      {
        type: 'minLength',
        value: '10'
      }
    ],
  },
  {
    type: 'text',
    label: 'Estado',
    name: 'state',
    placeholder: 'Selecciona un Estado',
    // validators: [
    //   {
    //     type: 'required',
    //   }
    // ],
  },
  {
    type: 'text',
    label: 'Ciudad',
    name: 'fk_id_city',
    placeholder: 'Selecciona una Ciudad del Estado',
    disabled: true,
    validators: [
      {
        type: 'required',
      }
    ],
  },
  {
    type: 'number',
    label: 'Código Postal',
    name: 'postal_code',
    placeholder: 'Código Postal',
    validators: [
      {
        type: 'required',
      }
    ],
  },
];

export const PRE_SIGNUP_CLIENT_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
  {
    type: 'text',
    label: 'Nombre',
    name: 'firstName',
    placeholder: 'Nombre',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'pattern',
        value: /^[a-zA-Z\s]*$/,
      }
    ],
  },
  {
    type: 'text',
    label: 'Apellido',
    name:'lastName',
    placeholder: 'Apellido',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'pattern',
        value: /^[a-zA-Z\s]*$/,
      }
    ],

  }, {
    type: 'text',
    label: 'Teléfono',
    name: 'phoneNumber',
    placeholder: 'Teléfono',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'maxLength',
        value: '15'
      },
      {
        type: 'minLength',
        value: '10'
      }
    ],
  }, 
  {
    type: 'password',
    label: 'Contraseña',
    name: 'password',
    placeholder: 'Contraseña',
    validators: [
      {
        type: 'required',
      }, 
    ],
  }, 
  {
    type: 'password',
    label: 'Confirmar Contraseña',
    name: 'passwordConf',
    placeholder: 'Confirmar Contraseña',
    validators: [
      {
        type: 'required',
      },
    
    ],
  },
  {
    type: 'text',
    label: 'Nombre del Despacho',
    name: 'name_dispatch',
    placeholder: 'Nombre del Despacho',
  },
 
  {
    type: 'text',
    label: 'Estado',
    name: 'state',
    placeholder: 'Selecciona un Estado',
    // validators: [
    //   {
    //     type: 'required',
    //   }
    // ],
  },
  {
    type: 'text',
    label: 'Ciudad',
    name: 'fk_id_city',
    placeholder: 'Selecciona una Ciudad del Estado',
    disabled: true,
    validators: [
      {
        type: 'required',
      }
    ],
  },
  {
    type: 'number',
    label: 'Código Postal',
    name: 'postal_code',
    placeholder: 'Código Postal',
    validators: [
      {
        type: 'required',
      }
    ],
  }
 
];

export const ACCEPT_INVITATION_CONTROL: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    disabled: true,
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
  {
    type: 'text',
    label: 'Nombres',
    name: 'firstName',
    placeholder: 'Nombres',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Apellidos',
    name: 'lastName',
    placeholder: 'Apellidos',
  },
  {
    type: 'text',
    label: 'Teléfono',
    name: 'phone_number',
    placeholder: 'Teléfono',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'maxLength',
        value: '15',
      },
      {
        type: 'minLength',
        value: '10',
      },
    ],
  },
  {
    type: 'text',
    label: 'Ciudad',
    name: 'fkIdCity',
    placeholder: 'Ciudad',
  },
  {
    type: 'text',
    label: 'Estado',
    name: 'fkIdState',
    placeholder: 'Estado',
  },
  {
    type: 'number',
    label: 'Código Postal',
    name: 'postal_code',
    placeholder: 'Código Postal',
    validators: [],
  },
  ...PASSWORD_CHANGE_CONTROLS,
];


export const REGISTER_CLIENT_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Nombre',
    name: 'firstName',
    placeholder: 'Nombre',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'text',
    label: 'Apellido',
    name: 'lastName',
    placeholder: 'Apellido',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'email',
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'email',
      },
    ],
  },
  {
    type: 'text',
    label: 'Teléfono',
    name: 'phoneNumber',
    placeholder: 'Teléfono',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'maxLength',
        value: '15'
      },
      {
        type: 'minLength',
        value: '10'
      }
    ],
  },
  {
    type: 'password',
    label: 'Contraseña',
    name: 'password',
    placeholder: 'Contraseña',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'pattern',
        value: /^(.{8,})$/,
      },
      {
        type: 'pattern',
        value: /[A-Z]/,
       
      },
      {
        type: 'pattern',
        value: /[a-z]/,
     
      },
      {
        type: 'pattern',
        value: /[0-9]/,
      
      },
      {
        type: 'pattern',
        value: /[~`!@#$%^&*()_\-\+={[}\]\|:;"'<,>\.?\/]/,
       
      },
    ],
  },
  {
    type: 'password',
    label: 'Confirmar Contraseña',
    name: 'passwordConf',
    placeholder: 'Confirmar Contraseña',
    validators: [
      {
        type: 'required',
      },
    
    ],
  },
];
