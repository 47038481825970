<div
    class="bg-pearl-100 rounded shadow-lg overflow-hidden w-[100%] sm:w-[90%] max-w-[100%] max-h-[85svh] m-auto p-4 flex flex-col gap-3">
    <div class="pb-1 border-b bg-pearl-100   border-slate-200   sticky top-0">
        <div class="flex justify-between items-center">
            <div class="font-semibold text-primary-100">Código de Validación
            </div>
            <button class="text-slate-400 hover:text-slate-500 close-overlay-directive">
                <div class="sr-only">{{ 'CLOSE_STRING' | translate }}</div>
                <i class="fa-duotone fa-xmark error-icon"
                    style="font-size:1rem; --fa-secondary-opacity: 1 !important;"></i>
            </button>
        </div>
    </div>

    <span class="text-medium">{{promptMessage}}</span>
    <form [formGroup]="form" autocomplete="off">
        <div class="flex gap-2 p-4 justify-center">
            <input type="text" (input)="onInputChanged(1, $event, inputNumber2)" formControlName="number1" class="form-input max-w-[2.75rem] min-h-[2.75rem] text-center text-2xl" [ngClass]="{'!border-rose-300' : (form.controls['number1'].errors && form.controls['number1'].touched)}" maxlength="6" #inputNumber1>
            <input type="text" (input)="onInputChanged(2, $event, inputNumber3)" formControlName="number2" class="form-input max-w-[2.75rem] min-h-[2.75rem] text-center text-2xl" [ngClass]="{'!border-rose-300' : (form.controls['number2'].errors && form.controls['number2'].touched)}" maxlength="1" #inputNumber2>
            <input type="text" (input)="onInputChanged(3, $event, inputNumber4)" formControlName="number3" class="form-input max-w-[2.75rem] min-h-[2.75rem] text-center text-2xl" [ngClass]="{'!border-rose-300' : (form.controls['number3'].errors && form.controls['number3'].touched)}" maxlength="1" #inputNumber3>
            <input type="text" (input)="onInputChanged(4, $event, inputNumber5)" formControlName="number4" class="form-input max-w-[2.75rem] min-h-[2.75rem] text-center text-2xl" [ngClass]="{'!border-rose-300' : (form.controls['number4'].errors && form.controls['number4'].touched)}" maxlength="1" #inputNumber4>
            <input type="text" (input)="onInputChanged(5, $event, inputNumber6)" formControlName="number5" class="form-input max-w-[2.75rem] min-h-[2.75rem] text-center text-2xl" [ngClass]="{'!border-rose-300' : (form.controls['number5'].errors && form.controls['number5'].touched)}" maxlength="1" #inputNumber5>
            <input type="text" (input)="onInputChanged(6, $event)" formControlName="number6" class="form-input max-w-[2.75rem] min-h-[2.75rem] text-center text-2xl" [ngClass]="{'!border-rose-300' : (form.controls['number6'].errors && form.controls['number6'].touched)}" maxlength="1" #inputNumber6>
        </div>
    </form>

    <div class="pt-3 border-t bg-pearl-100 border-slate-200 sticky bottom-0">
        <div class="flex flex-wrap justify-end space-x-2">
            <button class="uisr-secondary-button close-overlay-directive">{{ 'CANCEL_STRING' | translate |
                titlecase}}</button>
            <button class="uisr-primary-button" [loadingElement]="resources.validate2FACode" (click)="onFormSubmit()">
                <i class="fa-duotone fa-shield-check bookmark-primary-icon pr-1" style="font-size: 1.2rem;"></i>
                Validar
            </button>
        </div>
    </div>
</div>