import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByBool',
  standalone: true
})
export class FilterByBoolPipe implements PipeTransform {

  transform(notifications: any[], field: string, value: boolean): any[] {
    return notifications
      .sort((a, b) => {
        // order by createdAt
        return a.createdAt > b.createdAt ? -1 : 1;
      })

      .filter(notification => notification[field] == value);
  }

}