import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import device from 'current-device';
import { isEqual } from 'lodash';
import { distinctUntilChanged, lastValueFrom, map, Subject } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { SUBSCRIPTION_STATUS } from 'src/app/core/constants/subscription-status.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { UisrApiServiceV2 } from './uisr-api.service-v2';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private hash?: string;
  private userData?: UserData;
  private subscription = new Subject();
  private readonly store = inject(Store);
  private readonly _apiService = inject(UisrApiServiceV2);

  constructor() {
    this._subscribeToUserSubscription();
    this._subscribeToUserData();
  }

  /** Al obtener la suscripción del usuario se verifica si se debe mostrar el botón de intercom fuera del modal de contacto */
  private _subscribeToUserSubscription() {
    this.subscription
      .pipe(distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe({
        next: (subscription) => {
          if (
            subscription &&
            (subscription.fkIdStatusSuscrip == SUBSCRIPTION_STATUS.TRIALING || // Mostrar a los que están en pruebas
              subscription.fkIdStatusSuscrip == SUBSCRIPTION_STATUS.CANCELLED || // Mostrar a los que están cancelados
              !subscription.stripeObject?.default_payment_method) // Mostrar a los que no tienen un método de pago guardado
          ) {
            this.updateIntercom({ hide_default_launcher: false });
          } else if (device.desktop()) {
            this.updateIntercom({ hide_default_launcher: true });
          }
        },
      });
  }

  /** Con cada cambio del userData, hay que actualizar la sesión en intercom */
  private _subscribeToUserData() {
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilChanged(isEqual),
        untilDestroyed(this)
      )
      .subscribe((data?: UserData) => {
        if (data && data.id_users) {
          this.userData = data;
          this.updateIntercom({
            email: data.email,
            user_id: data.id_users,
            name: data.first_name,
          });
        }
      });
  }

  /** Inicia la sesión en intercom */
  bootIntercom(data: any): void {
    if (!window.Intercom) return;
    window.Intercom('boot', { ...data, hide_default_launcher: true });
  }

  /** Termina la sesión del usuario en intercom */
  shutdownIntercom(): void {
    if (!window.Intercom) return;
    window.Intercom('shutdown');
  }

  /** Muestra el botón por defecto de intercom que aparece en la parte inferior derecha */
  showIntercom(): void {
    if (!window.Intercom) return;
    window.Intercom('show');
  }

  /** Verifica si existe el hash del usuario y si no existe lo solicita para actualizar los datos de intercom */
  async updateIntercom(data?: any) {
    const lastRequestAt = Math.floor(new Date().getTime() / 1000);
    if (!this.userData?.id_users) return;

    await this._getHash();

    if (!this.hash || !window.Intercom) return;

    window.Intercom('update', {
      last_request_at: lastRequestAt,
      ...data,
      user_hash: this.hash,
    });
  }

  /** Obtiene el hash del usuario en sesión para validar la identidad frente a intercom */
  async _getHash() {
    if (!this.hash) {
      this.hash = await lastValueFrom(
        this._apiService
          .get(RESOURCES.intercomHash)
          .pipe(map((res: APIResponse<string>) => res.data))
      );
    }
  }
}
