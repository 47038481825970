<div class="login-container mx-auto px-2 sm:px-4 py-4 sm:py-8 max-w-[270px] sm:max-w-none" *ngIf="!(showWelcomeMessage | async)">
    <h1 class="text-3xl text-slate-800 font-bold mb-6 -intro-x">
        Ingresar
    </h1>
    <div *ngIf="dialogData?.alreadyRegistered" class="p-2 rounded bg-indigo-100 border border-indigo-200 mb-6 text-sm">
        <p translate="SES-501"></p>
    </div>
    <form [formGroup]="loginForm">
        <div class="space-y-4">
            <div class="-intro-x" *ngFor="let control of formControls">
                <app-input-generic [loadingElement]="resources.login" [objectConfig]="control" [formControlName]="control.name"></app-input-generic>
            </div>
        </div>
        <div class="flex flex-col-reverse sm:flex-row items-start sm:items-center justify-between mt-6 -intro-x gap-4">
            <a class="text-sm underline hover:no-underline" [routerLink]="'/security/password-recovery'">
                ¿Olvidaste tu contraseña?
            </a>
            <button class="uisr-primary-button" [loadingElement]="resources.login"
                (click)="onFormSubmit()">
                Iniciar Sesión
            </button>
        </div>
        <div class="pt-5 mt-6 border-t border-slate-200 flex items-start justify-center -intro-x">
            <asl-google-signin-button class="btn mt-1" style="padding: 0; max-height: 48px; overflow: hidden;"
                type="standard" shape="rectangular" theme="outline" text="continue_with" size="large" locale="es" [width]="getButtonWidth()"
                logo_alignment="center">
            </asl-google-signin-button>
        </div>
        <div class="flex justify-start sm:justify-center">
            <a [routerLink]="[ '/security/privacy']" class="text-sm mt-6 underline hover:no-underline">Ver Aviso de Privacidad</a>
        </div>
    </form>
</div>

<div class="min-w-[310px] max-w-[320px] mx-auto w-full px-4 py-8 -intro-x" *ngIf="showWelcomeMessage | async">
    <h3 class="text-3xl text-slate-800 font-bold -intro-x">
        {{ 'WELCOME_TO_STRING' | translate }}
        <br>
        {{ 'APP_NAME' | translate}}
    </h3>
    <p class="mt-5 text-lg">
        {{ 'REGISTRATION_APPRECIATION_NO_VALIDATION' | translate }}
    </p>
    <div class="mt-5">
        <button (click)="navigateTo('/main')" class="btn bg-primary-100 hover:bg-primary-200 text-pearl-100">
            {{ 'SIGN_IN_PROMPT' | translate }}
        </button>
    </div>
</div>

<ng-template appModalOverlay #tpl2FA="appModalOverlay">
    <app-code-validation (onSubmit)="handleTwoFactor($event)"></app-code-validation>
</ng-template>