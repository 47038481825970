export const COLLECTION_TYPES = {
  FAVORITES: 1,
  RECENT: 2,
  CUSTOM: 3,
  DOSSIERS: 4,
};



export const ORDER_TYPES = {
  ASCENDING: {
    text: 'fa-solid fa-angle-up ml-1 ',
    color: 'bg-sky-500',
    position: 1,
    value: 'ASC',
    name: 'Ascendente',
    description:
      'Ocultar ordenamiento',
  },
  NONE: {
    text: '',
    color: 'bg-teal-500',
    position: 2,
    value: '',
    name: 'Ninguno',
    description:
      'Ordenar Descendente',
  },
  DESCENDING: {
    text: 'fa-solid ml-1 fa-angle-down',
    color: 'bg-indigo-500',
    position: 3,
    value: 'DESC',
    name: 'Descendente',
    description:
      'Ordenar Ascendente',
  },
 
};