import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import anime from 'animejs';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { distinctUntilKeyChanged, filter } from 'rxjs';
import { StringConditionedStylingDirective } from 'src/app/shared/directives/string-conditioned-styling.directive';
import { MenuModule } from 'src/app/shared/modules/menu.module';
import { ValidateAccessDirective } from '../../directives/validate-access.directive';
import { MenuItem } from '../../interfaces/menu-item.interface';
import { UserData } from '../../models/user-data';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { UisrAnalyticsService } from '../../services/analytics.service';

@UntilDestroy()
@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxTippyModule,
    RouterModule,
    StringConditionedStylingDirective,
    MenuModule,
    ValidateAccessDirective,
  ],
})
export class MenuItemComponent implements OnInit {
  currentUrl: string | null = null;
  userData?: UserData;
  isHovered: boolean = false;

  public inactiveIcon: Object = {
    '--fa-primary-color': '#475569',
    '--fa-secondary-color': '#94a3b8',
    '--fa-primary-opacity': '1',
    '--fa-secondary-opacity': '1',
  };

  public activeIcon: Object = {
    '--fa-primary-color': '#817DF2',
    '--fa-secondary-color': '#a5b4fc',
    '--fa-primary-opacity': '1',
    '--fa-secondary-opacity': '1',
  };

  @Input() menuItem: any = null;
  @Input() sidebarExpanded: boolean = false;

  @Output() sidebarExpandRequest: EventEmitter<boolean> = new EventEmitter();

  private readonly _location = inject(Location);
  private readonly _router = inject(Router);
  private readonly _analyticsService = inject(UisrAnalyticsService);
  private readonly _store = inject(Store)
  private readonly _translateService = inject(TranslateService)

  ngOnInit(): void {
    this.currentUrl = this._location.path().split('?')[0];

    this._store
      .pipe(
        select(UserDataFull),
        distinctUntilKeyChanged('id_users'),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.userData = data;
      });

    this._router.events
      .pipe(
        filter((event: any) => {
          return event.routerEvent instanceof NavigationEnd;
        })
      )
      .subscribe(() => (this.currentUrl = this._location.path().split('?')[0]));
  }

  // Nueva función para capturar el evento hover
  onHover(state: boolean) {
    this.isHovered = state;
  }

  // Función modificada para mostrar íconos cuando sea necesario
  showIcon(menuItem: MenuItem): boolean {
    return (
      !this.sidebarExpanded &&
      menuItem.label === 'LAW_FIRM_STRING' &&
      this.isHovered
    );
  }

  toggleMenuItem(menuItem: MenuItem, event: Event): void {
    const childrenContainer = (event.target as HTMLElement)
      .closest('div')
      ?.querySelector('ul');

    if (!childrenContainer) {
      return;
    }

    if (menuItem.status) {
      // Colapsar el menú
      anime({
        targets: childrenContainer,
        height: 0,
        duration: 300,
        easing: 'easeInOutQuad',
        complete: () => {
          menuItem.status = false;
          childrenContainer.style.display = 'none'; // Ocultar después de la animación
        },
      });
    } else {
      // Expandir el menú
      childrenContainer.style.display = 'block'; // Asegurarse de que esté visible antes de animar
      const height = childrenContainer.scrollHeight;
      anime({
        targets: childrenContainer,
        height: [0, height], // Animar desde 0 hasta la altura máxima
        duration: 300,
        easing: 'easeInOutQuad',
        complete: () => {
          menuItem.status = true;
          childrenContainer.style.height = 'auto'; // Resetear la altura para que sea automática
        },
      });
    }
  }

  showChild(item: MenuItem) {
    return item.url != '/law-firm/cliente-files' || !this.userData?.idWorkspace;
  }

  trackChildClick(menuChild: MenuItem) {
    if (menuChild.disabled || !menuChild.url) {
      return;
    }
    this.trackNavigation(menuChild.url, menuChild.label);
  }

  trackNavigation(url: string, label: string) {
    if (url.includes('law-firm') || url.includes('search')) {
      const parsedLabel = this._translateService.instant(label);
      this._analyticsService.trackMenuClick(parsedLabel, this.userData!);
    }
  }

  navigateFromParent(menuItem: MenuItem): void {
    if (menuItem.url && !this.currentUrl?.includes(menuItem.url)) {
      this._router.navigateByUrl(menuItem.url);
      this.trackNavigation(menuItem.url, menuItem.label);
    }

    if (menuItem.label === 'CALENDAR' && this.userData?.id_users) {
      this._analyticsService.trackCalendarClick(this.userData.id_users, 'menu');
    }
  }

  getParentStatus(): boolean {
    const hasChildren = this.menuItem.children && this.menuItem.children[0];
    return (
      !hasChildren &&
      this.menuItem.url == this.currentUrl &&
      this.sidebarExpanded
    );
  }

  showActiveStyle(): boolean {
    const hasChildren = this.menuItem.children && this.menuItem.children[0];
    // El estilo activo se debe mostrar si menuItem no tiene children y la url hace match exacto con su menuItem.url o si tiene children y la url incluye menuItem.url
    return (
      (!hasChildren && this.menuItem.url == this.currentUrl) ||
      (hasChildren && this.currentUrl?.includes(this.menuItem.url))
    );
  }

  hasAllItemsDisabled(menuItem: MenuItem): boolean {
    let allItemsDisabled: boolean = false;
    if (!menuItem.children || !menuItem.children[0]) {
      return allItemsDisabled;
    } else {
      for (let index = 0; index < menuItem.children.length; index++) {
        if (!menuItem.children[index].disabled) {
          return allItemsDisabled;
        }
        allItemsDisabled = true;
      }
    }
    return allItemsDisabled;
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
