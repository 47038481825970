import { Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UisrAuthService } from 'src/app/core/services/uisr-auth.service';
import { DialogService } from '../../../../../core/services/dialog.service';
import { SimpleDocPickerComponent } from '../../../simple-doc-picker/simple-doc-picker.component';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { ALLOWED_AMPARO_EXTS } from '../../amparo-ext.constants';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-chat-thread',
  templateUrl: './chat-thread.component.html',
  styleUrls: ['./chat-thread.component.scss'],
})
export class ChatThreadComponent {
  assistantChatService = inject(AssistantChatService);

  private readonly _authService = inject(UisrAuthService);
  private readonly _dialogService = inject(DialogService);
  private readonly _router = inject(Router);

  /** Abre el modal del selector de documentos simple y obtiene los ids de los archivos seleccionados*/
  viewDocs() {
    const docIds = Array.from(
      new Set(
        this.assistantChatService.documents?.map(
          (doc: any) => doc.idActivityFile
        )
      )
    );

    this._dialogService
      .openDialog(SimpleDocPickerComponent, {
        data: {
          prompt: 'Selecciona archivos desde tus asuntos o colecciones para agregarlos a la conversación.',
          extensions: ALLOWED_AMPARO_EXTS,
          existing: docIds,
          // sizeLimit: 2097152,
        },
      })
      .subscribe((selection: any) => {
        if (selection) {
          this.assistantChatService.addDocs(selection);
        }
      });
  }

  /** Determina si la opción de agregar documentos está disponible */
  canAddFiles() {
    const settings = this._authService.assistantSettings.getValue();
    return !settings || !settings.idAssistantSettings || settings.allowAccess;
  }

  /** retorna el texto a mostrar al cliente al hacer hover sobre el botón de carga de documentos según la configuración del despacho y permisos del usuario */
  getTippy() {
    if (this.canAddFiles()) {
      return 'Agregar Documentos a la Conversación'
    } else if (this._authService.hasAccess('asistente:configurar')) {
      return 'Activa el acceso para Amparo IA para poder cargar documentos.'
    } else {
      return 'Contacta al administrador del despacho para activar la carga de documentos a tus conversaciones con Amparo IA.'
    }
  }

  /** Verifica qué acción realizar al dar click en el botón de carga de archivos según la configuración del despacho y permisos del usuario */
  onDocsClick() {
    if (this.canAddFiles()) {
      this.viewDocs()
    } else if (this._authService.hasAccess('asistente:configurar')) {
      this._router.navigate(['/law-firm/settings/assistant']);
      this.assistantChatService.close();
    } else {
      return;
    }
  }
}
