<router-outlet></router-outlet>

<!-- New Version Detect Modal -->
<ng-template
	appModalOverlay
	#modalVersionUpdateOverlay="appModalOverlay"
	[closeOnEscape]="false"
	[closeBackdropClick]="false"
>
	<div class="bg-white rounded shadow-lg overflow-hidden w-[32rem] max-w-[90vw] max-h-[90dvh] m-auto flex flex-col">
		<div class="px-5 py-3 border-b bg-white border-slate-200 relative top-0">
			<div class="flex justify-between items-center">
				<div class="text-lg font-semibold text-primary-100">Actualización Detectada ⚙️</div>
				<!--
				<button
					(click)="modalVersionUpdateOverlay.close()"
					class="w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm"
				>
					<i
						class="fa-duotone fa-xmark opacity-75 hover:opacity-100"
						style="font-size: 1rem"
					></i>
				</button>
				-->
			</div>
		</div>
		<div class="p-4 relative overflow-y-auto">
			<div class="text-sm">
				<div class="space-y-2">
					<p>Se ha recibido una nueva versión de MiDespacho en tu navegador.</p>
					<div class="font-medium text-slate-800 mb-3">Actualiza para:</div>
					<ul class="space-y-2 mb-5">
						<li class="flex items-center">
							<svg
								class="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
								viewBox="0 0 12 12"
							>
								<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
							</svg>
							<div>Disfrutar las últimas características</div>
						</li>
						<li class="flex items-center">
							<svg
								class="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
								viewBox="0 0 12 12"
							>
								<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
							</svg>
							<div>Mantener tu cuenta segura</div>
						</li>
						<li class="flex items-center">
							<svg
								class="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
								viewBox="0 0 12 12"
							>
								<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
							</svg>
							<div>Prevenir errores</div>
						</li>
					</ul>
					<p class="text-xs text-slate-500">Al presionar el botón "Actualizar" aplicaremos los cambios y recargaremos la ventana actual.</p>
				</div>
			</div>
		</div>
		<div class="py-5 px-4 border-t bg-pearl border-slate-200 sticky bottom-0">
			<div class="flex flex-wrap justify-end space-x-2">
				<!--
				<button class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600 close-overlay-directive">Cancelar</button>
				-->
				<button
					class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white close-overlay-directive"
					(click)="applyUpdate()"
				>
					Actualizar
				</button>
			</div>
		</div>
	</div>
</ng-template>