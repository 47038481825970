export const ALLOWED_AMPARO_EXTS = [
  '.pdf',
  '.602',
  '.abw',
  '.cgm',
  '.cwk',
  '.doc',
  '.docx',
  '.docm',
  '.dot',
  '.dotm',
  '.hwp',
  '.key',
  '.lwp',
  '.mw',
  '.mcw',
  '.pages',
  '.pbd',
  '.ppt',
  '.pptm',
  '.pptx',
  '.pot',
  '.potm',
  '.potx',
  '.rtf',
  '.sda',
  '.sdd',
  '.sdp',
  '.sdw',
  '.sgl',
  '.sti',
  '.sxi',
  '.sxw',
  '.stw',
  '.sxg',
  '.txt',
  '.uof',
  '.uop',
  '.uot',
  '.vor',
  '.wpd',
  '.wps',
  '.xml',
  '.zabw',
  '.epub',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.svg',
  '.tiff',
  '.webp',
  '.web',
  '.htm',
  '.html',
  '.xlsx',
  '.xls',
  '.xlsm',
  '.xlsb',
  '.xlw',
  '.csv',
  '.dif',
  '.sylk',
  '.slk',
  '.prn',
  '.numbers',
  '.et',
  '.ods',
  '.fods',
  '.uos1',
  '.uos2',
  '.dbf',
  '.wk1',
  '.wk2',
  '.wk3',
  '.wk4',
  '.wks',
  '.123',
  '.wq1',
  '.wq2',
  '.wb1',
  '.wb2',
  '.wb3',
  '.qpw',
  '.xlr',
  '.eth',
  '.tsv',
]