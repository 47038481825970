export const SOURCES_WITH_IDS = [
  {
    label: 'Todas',
    id: 0,
  },
  {
    label: 'CJF',
    id: 2,
    index: 'cjf-documents',
  },
  {
    label: 'SCJN',
    id: 1,
    index: 'scjn-documents',
  },
  {
    label: 'Leyes',
    id: 3,
    index: 'leyes-biblio-documents',
  },
];

