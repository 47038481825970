<div class="uisr-stepper-container">
    <div class="uisr-stepper-step-navigation-bar max-w-md mx-auto w-full" *ngIf="showHeader">
        <div class="relative">
            <div class="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200" aria-hidden="true"></div>
            <div class="relative flex justify-between w-full">
                <button class="uisr-stepper-step"
                    [ngClass]="{'uisr-stepper-active': i <= selectedIndex, '!pointer-events-none': !allowStepChange}"
                    *ngFor="let step of steps; let i = index" (click)="selectStepByIndex(i)">
                    {{ i + 1 }}
                </button>
            </div>
        </div>
    </div>
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
</div>