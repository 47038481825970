import { Directive, ElementRef, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { DemoWidgetComponent } from '../components/assistant-chat/components/chat-widgets/demo-widget/demo-widget.component';
import { InternalRouterWidgetComponent } from '../components/assistant-chat/components/chat-widgets/internal-router-widget/internal-router-widget.component';

@Directive({
  selector: '[appChatWidgets]'
})
export class ChatWidgetsDirective implements OnInit {

  @Input() innerHTML!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    this.detectAndReplaceWidgets();
  }

  /**
   * Detectar los widgets en el contenido y reemplazarlos por los componentes
   */
  detectAndReplaceWidgets() {

    const element = this.el.nativeElement;

    // asiganmos el contenido al elemento
    this.renderer.setProperty(element, 'innerHTML', this.innerHTML);

    // Encontramos todas las etiquetas con el atributo widget="true"
    const widgets = element.querySelectorAll('[widget="true"]');

    widgets.forEach((widgetElement: HTMLElement) => {

      const widget = widgetElement.getAttribute('data');

      // Si el widget es valido
      if (widget) {

        // Dependiendo del valor de widgetData, creamos el componente correspondiente
        const widgetData = JSON.parse(widget);
        let componentRef = null;

        // Widget de demostracion
        if (widgetData.widget === 'calendar') {
          componentRef = this.createComponent(DemoWidgetComponent, widgetElement);
          const instance = componentRef.instance as DemoWidgetComponent;
          componentRef.changeDetectorRef.detectChanges();
        }

        // Widget para navegar internamente
        if (widgetData.widget === 'internalRouter') {
          componentRef = this.createComponent(InternalRouterWidgetComponent, widgetElement);
          const instance = componentRef.instance as InternalRouterWidgetComponent;
          componentRef.setInput('href', widgetData.href);
          componentRef.setInput('text', widgetData.text);
          componentRef.changeDetectorRef.detectChanges();
        }

        // Reemplazamos el contenido del widget con el componente asigando
        if(componentRef) widgetElement.replaceWith(componentRef.location.nativeElement);

      }
    });

  }

  /**
   * Crear componentes
   */
  createComponent(component: any, widgetElement: HTMLElement) {
    const componentRef = this.viewContainerRef.createComponent(component);
    return componentRef;
  }

}
