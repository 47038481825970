import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-uisr-stepper',
  templateUrl: './uisr-stepper.component.html',
  styleUrls: ['./uisr-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: UisrStepperComponent }],
  standalone: true,
  imports: [CdkStepperModule, NgTemplateOutlet, NgIf, NgFor, NgClass],
})
export class UisrStepperComponent extends CdkStepper {
  @Input() allowStepChange = true;
  @Input() showHeader = true;

  selectStepByIndex(index: number): void {
    if (this.allowStepChange) {
      this.selectedIndex = index;
    }
  }
}
