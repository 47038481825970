import { DialogRef } from "@angular/cdk/dialog";
import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateModule } from "@ngx-translate/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { BehaviorSubject } from "rxjs";
import { RESOURCES } from "src/app/core/constants/resource-service.constants";
import { APIResponse } from "src/app/core/interfaces/api.interface";
import { UisrAuthService } from "src/app/core/services/uisr-auth.service";
import { loadingState } from "src/app/shared/operators/loading-state.operator";
import { UisrApiServiceV2 } from "src/app/shared/services/uisr-api.service-v2";

@UntilDestroy()
@Component({
  selector: 'app-journey-complete',
  standalone: true,
  imports: [CommonModule, CarouselModule, NgxTippyModule, TranslateModule],
  templateUrl: './journey-complete.modal.html',
  host: {
    class: 'contents',
  },
})
export class JourneyCompleteModal {

    private readonly _authService = inject(UisrAuthService);
    private readonly router = inject(Router);
    private readonly _apiService = inject(UisrApiServiceV2);
    public readonly dialogRef = inject(DialogRef)

    resources = RESOURCES;
    loadingCertificate = new BehaviorSubject(false)
  
    downloadCertificate() {
      this._apiService
      .getCertificate(this.resources.downloadCertificateJourney, { responseType: 'blob' })
      .pipe( loadingState(this.loadingCertificate),untilDestroyed(this))
      .subscribe({
          next: (res: Blob) => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              
              // Crear un enlace para descargar el PDF
              const a = document.createElement('a');
              a.href = url;
              a.download = 'Certificado.pdf'; // Nombre del archivo
              a.click();
              
              // Limpiar URL del objeto para liberar memoria
              window.URL.revokeObjectURL(url);
          },
          error: (err) => console.error('Error downloading the file:', err)
      });
  }
}