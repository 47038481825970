<!-- Body -->
<div class="grow overflow-hidden relative flex items-end">
    <app-chat-window></app-chat-window>
</div>

<!-- Footer -->
<div>
    <div class="flex items-center justify-between bg-white border-slate-200 px-4 sm:px-6 md:px-5 py-3">

        <!-- Add Docs -->
        <div [ngxTippy]="getTippy()" [tippyProps]="{ placement: 'top', hideOnClick: true }"
            class="grid place-content-center">
            <button *appValidateAccess="'asistente:documentos'" id="assistant-add-docs"
                class="shrink-0 size-[2.375rem] mr-1 rounded grid place-content-center"
                [ngClass]="canAddFiles() ? 'text-slate-400 hover:text-slate-500 hover:bg-slate-300/60 hover:shadow-sm' : 'text-slate-300 text-opacity-95'"
                (click)="onDocsClick()" [disabled]="assistantChatService.loadingStates.sendingMessage.value || assistantChatService.loadingStates.activeRun.value">
                <i class="fa-regular fa-paperclip-vertical text-[1rem]"></i>
            </button>
        </div>

        <!-- Message input -->
        <app-chat-input></app-chat-input>

    </div>
</div>