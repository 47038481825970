import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-internal-router-widget',
  templateUrl: './internal-router-widget.component.html'
})
export class InternalRouterWidgetComponent {

  @Input() href: string = '';
  @Input() text: string = '';

  constructor() { }
  
}
