import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleDocPickerComponent } from '../simple-doc-picker.component';
import { ExtToIconPipe } from 'src/app/features/law-firm/pipes/ext-to-icon.pipe';

@Component({
  selector: 'app-simple-search-object-data',
  standalone: true,
  imports: [CommonModule, ExtToIconPipe],
  templateUrl: './simple-search-object-data.component.html'
})
export class SimpleSearchObjectDataComponent {
  container = inject(SimpleDocPickerComponent);
}
