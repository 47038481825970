import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssistantChatService } from '../../services/assistant-chat.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent {
  chatForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public assistantChatService: AssistantChatService
  ) {
    this.chatForm = this.formBuilder.group({
      message: [
        '',
        [
          Validators.required,
          (control: AbstractControl) => {
            // Validar que el campo no sean espacios en blanco o saltos de linea
            const value = control.value ? control.value.trim() : control.value;
            return value == '' ? { empty: true } : null;
          },
        ],
      ],
    });

    this._subscribeToUpdateReq();
  }

  /** Se suscribe a solicitudes de cambio en el input realizados a través del servicio compartido del asistente, actualiza el form con cada solicitud */
  private _subscribeToUpdateReq() {
    this.assistantChatService.updateMessage
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (val) => {
          this.chatForm.controls['message'].patchValue(val);
        },
      });
  }

  /** Deshabilitar envío de mensaje */
  get disabledSend() {
    return (
      this.assistantChatService.loadingStates.sendingMessage.value ||
      this.assistantChatService.loadingStates.creatingThread.value ||
      this.assistantChatService.loadingStates.addingDocuments.value ||
      this.assistantChatService.loadingStates.deletingDocuments.value ||
      this.assistantChatService.loadingStates.activeRun.value
    );
  }

  /** Emitir envío de mensaje */
  sendMessage() {
    if (this.chatForm.valid) {
      if (this.disabledSend) {
        return;
      }

      this.assistantChatService.sendMessage(this.chatForm.value.message);
      this.chatForm.reset();
    }
  }
}
