import { Component, inject, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { isEqual } from 'lodash';
import { distinctUntilChanged, interval, Subject, takeUntil } from 'rxjs';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import EJ2_LOCALE from 'src/assets/ej2/ej2-es.json';
import { environment } from 'src/environments/environment';
import { RESOURCES } from '../../constants/resource-service.constants';
import { UserData } from '../../models/user-data';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { SwUpdate } from '@angular/service-worker';
import { ModalOverlayDirective } from 'src/app/shared/directives/modal-overlay.directive';

L10n.load({ es: EJ2_LOCALE.es });
setCulture('es');

declare var gtag: (
  arg0: string,
  arg1: string,
  arg2: { page_path: string; user_id: any }
) => void;

declare global {
  interface Window {
      Intercom:any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  @ViewChild('modalVersionUpdateOverlay') modalVersionUpdateOverlay!: ModalOverlayDirective;

  title = 'mi-despacho-app';
  lastVisitedUrl: string | null = null;
  navigationStartTime: number | null = null;
  navigationEndTime: number | null = null;
  totalTime: number | null = null;
  resources = RESOURCES;
  userData?: UserData;

  private unsubscribe = new Subject<void>();

  private readonly _ngSelectConfig = inject(NgSelectConfig);
  private readonly _intercomService = inject(IntercomService);
  public _worker = inject(SwUpdate);

  constructor(private router: Router, private store: Store) {
    this._ngSelectConfig.notFoundText = 'No se encontraron resultados';
    this._ngSelectConfig.typeToSearchText = 'Escribe para buscar';
    this._ngSelectConfig.loadingText = 'Cargando...';

    this.store
      .pipe(select(UserDataFull), distinctUntilChanged(isEqual), takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.userData = data;
      });

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.idGA, {
            page_path: event.urlAfterRedirects,
            user_id: this.userData?.id_users || null,
          });

          if (this.userData?.id_users) {
            this._intercomService.updateIntercom();
          } else {
            this._intercomService.shutdownIntercom();
          }
        }
      },
    });

    if (this._worker.isEnabled) {
      this.serviceWorkerAppUpdater();
    }
  }

	/**
	 * Iniciar comprobacion de actualizaciones
	 */
	serviceWorkerAppUpdater() {
		const intervalSeconds = 10 * 1000;

		// Comprobar cada intervalSeconds si hay cambios en la app
		interval(intervalSeconds).subscribe(() => {
      console.log ('Checking for update...');
			this._worker.checkForUpdate();
		});

		// Suscribirse a los cambios detectados por el worker
		this._worker.versionUpdates.subscribe((event) => {

      console.log(event);

			//NO_NEW_VERSION_DETECTED
			//VERSION_DETECTED
			//VERSION_READY

			if (event.type == 'VERSION_READY') {
        console.log('Show modal version update');
				this.modalVersionUpdateOverlay.open();
			}
		});

		/*this.worker.unrecoverable.subscribe((event) => {
      console.log(event);
    });*/
	}

	/**
	 * Actualizar la app
	 */
	applyUpdate() {
    console.log('Applying update...');
		this._worker.activateUpdate().then(() => {
			window.location.reload();
		});
	}

}
