<button *ngFor="let searchObject of container.filteredSearchObjects" type="button"
  class="enabled:hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent"
  (click)="container.onSearchObjectClick(searchObject)"
  [disabled]="container.existingSearchObjectIds.includes(searchObject.id_search_object)"
  [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative': container.selectedSearchObjects.has(searchObject) || container.existingSearchObjectIds.includes(searchObject.id_search_object)}">
  <i class="fa-regular text-[1rem] text-slate-600" [ngClass]="'pdf' | extToIcon"></i>
  <span class="font-medium text-slate-600 truncate text-sm">{{ searchObject.heading | titlecase }}</span>
  <div class="px-3 ml-auto pointer-events-none flex items-center">
    <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
      [ngClass]="container.selectedSearchObjects.has(searchObject) || container.existingSearchObjectIds.includes(searchObject.id_search_object) ? 'visible': 'invisible'">
      <i class="fa-solid fa-check text-[0.9rem]"></i>
    </div>
  </div>
</button>