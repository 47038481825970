import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';

@Component({
  selector: 'app-code-validation',
  templateUrl: './code-validation.component.html',
  host: {
    contents: 'true',
  },
})
export class CodeValidationComponent {
  promptMessage = 'Usa código enviado a tu correo.';
  numberOnlyPattern = /^[0-9]+$/;
  resources = RESOURCES;
  form: FormGroup;

  @ViewChild('inputNumber1', { static: false }) inputNumber1!: ElementRef;
  @ViewChild('inputNumber2', { static: false }) inputNumber2!: ElementRef;
  @ViewChild('inputNumber3', { static: false }) inputNumber3!: ElementRef;
  @ViewChild('inputNumber4', { static: false }) inputNumber4!: ElementRef;
  @ViewChild('inputNumber5', { static: false }) inputNumber5!: ElementRef;
  @ViewChild('inputNumber6', { static: false }) inputNumber6!: ElementRef;

  @Output() onSubmit: EventEmitter<any> = new EventEmitter(false);

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      number1: [
        null,
        [Validators.required, Validators.pattern(this.numberOnlyPattern)],
      ],
      number2: [
        null,
        [Validators.required, Validators.pattern(this.numberOnlyPattern)],
      ],
      number3: [
        null,
        [Validators.required, Validators.pattern(this.numberOnlyPattern)],
      ],
      number4: [
        null,
        [Validators.required, Validators.pattern(this.numberOnlyPattern)],
      ],
      number5: [
        null,
        [Validators.required, Validators.pattern(this.numberOnlyPattern)],
      ],
      number6: [
        null,
        [Validators.required, Validators.pattern(this.numberOnlyPattern)],
      ],
    });

    setTimeout(() => {
      this.inputNumber1.nativeElement.focus();
    }, 250);
  }

  onInputChanged(index: number, event: any, nextInput: any = null) {
    const value = event.target['value'];
    if (value) {
      const digits = value.split('');

      if (value.length === 6) {
        for (let i = 0; i < 6; i++) {
          const digit = digits[i] || '';
          this.form.controls[`number${i + 1}`].setValue(digit);
        }
      }

      if (value.length === 1 && index < 6) {
        if (nextInput) {
          nextInput.focus();
        }
      }
      if (index === 6) {
        this.onFormSubmit();
      }
    }
  }

  onFormSubmit() {
    if (this.form.valid) {
      const code = Object.values(this.form.value).join('');
      this.onSubmit.emit(code);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
