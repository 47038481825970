import { Injectable, signal } from '@angular/core';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { UisrApiServiceV2 } from './uisr-api.service-v2';
import {
  CollectionLite,
  SearchObjectLite,
} from 'src/app/features/search/interfaces/collections.interfaces';
import { COLLECTION_TYPES } from 'src/app/features/search/constants/collections.constants';

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {
  private resources = RESOURCES;
  public userCollections = signal<CollectionLite[]>([]);
  public favorites = signal<SearchObjectLite[]>([]);

  constructor(private apiService: UisrApiServiceV2) {
    this.loadUserCollections();
  }

  private updateUserCollections(newCollections: CollectionLite[]): void {
    this.userCollections.set(newCollections);
  }

  private updateFavorites(searchObjects: SearchObjectLite[]): void {
    this.favorites.set(searchObjects);
  }

  public loadUserCollections(): void {
    this.apiService
      .get(this.resources.getCollections, { num: 10000 })
      .subscribe({
        next: (res) => {
          this.updateUserCollections(res.collections.data);
          const favCollection = this.userCollections().find(
            (col) => col.fk_id_type_collection == COLLECTION_TYPES['FAVORITES']
          );
          this.updateFavorites(favCollection?.search_objects || []);
        },
      });
  }
}
