import { Pipe, PipeTransform } from '@angular/core';
import { UisrTools } from 'src/app/core/utils/uisr-tools';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {

  transform(size: number) {
    return UisrTools.formatBytes(size, 1);
  }

}