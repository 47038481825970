<div class="-intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
    <div [ngClass]="passLevel > 0 ? 'bg-green-700' : 'bg-slate-100'" class="col-span-3 h-full rounded"></div>
    <div [ngClass]="passLevel > 1 ? 'bg-green-700' : 'bg-slate-100'" class="col-span-3 h-full rounded"></div>
    <div [ngClass]="passLevel > 2 ? 'bg-green-700' : 'bg-slate-100'" class="col-span-3 h-full rounded"></div>
    <div [ngClass]="passLevel > 4 ? 'bg-green-700' : 'bg-slate-100'" class="col-span-3 h-full rounded"></div>
</div>
<div *ngIf="!isInvalidPassword() && (passLevel == 5)"
    class="mt-2 text-xs font-medium max-xs:max-w-[300px]">¡La contraseña es válida!</div>
<div *ngIf="isTouched || hasChanged">
    <div *ngIf="!isInvalidPassword() || (passLevel < 5)"
        class="text-danger text-xs mt-2">
        <div *ngIf="!isInvalidPassword() && hasErrors">
            Por favor introduzca una contraseña válida.</div>
        <div *ngIf="isInvalidPassword()" class="text-xs font-medium max-xs:w-[300px]">
            <p>La contraseña debe contener:</p>
            <ul class="-intro-x text-xs xs:text-sm">
                <ng-container *ngFor="let check of passChecks">
                    <li *ngIf="!isValidCheck(check.type)" class="-intro-x text-rose-600 ml-3 py-1 gap-x-2 flex items-center">
                        <ng-icon class="text-lg" name="matCancelOutline"></ng-icon>
                        {{check.message}}
                    </li>
                </ng-container>
            </ul>
        </div>

    </div>
</div>