<div *ngIf="dialogData && dialogRef"
  class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center">
  <span class="font-semibold text-primary-100" translate="EDIT_TEAM"></span>
  <!-- Cerrar el modal -->
  <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
    class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
    (click)="dialogRef.close()">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid text-[1rem] fa-xmark"></i>
  </button>
</div>

<div
  [ngClass]="dialogData && dialogRef ? 'overflow-auto p-3 max-h-[calc(85svh-11.4rem)] gap-4 w-[85svw] max-w-3xl' : 'contents'">
  <div class="grow flex overflow-x-auto border-b border-slate-200 pb-2">
    <button type="button" (click)="changeType('all')" class="cursor-pointer grid place-items-center" id="tab-all">
      <a id="tab-all" class="px-4 max-md:py-2 text-slate-400 active:text-indigo-500 font-medium active:font-semibold"
        [ngClass]="{'active': selectedType == 'all'}" translate="MEMBERS_STRING">
      </a>
    </button>
    <button type="button" (click)="changeType('groups')" class="cursor-pointer grid place-items-center" id="tab-groups">
      <a id="tab-groups" class="px-4 max-md:py-2 text-slate-400 active:text-indigo-500 font-medium active:font-semibold"
        [ngClass]="{'active': selectedType == 'groups'}" translate="GROUPS_STRING">
      </a>
    </button>
  </div>
  <div class="grid gap-2 mt-4" [ngClass]="{'md:grid-cols-2': !dialogRef}">
    <ng-select *ngIf="selectedType == 'groups'" [multiple]="true" [clearable]="false" (change)="onGroupChange($event)"
      placeholder="Selecciona grupos de usuarios" class="ng-select-override"
      [appendTo]="dialogData && dialogRef ? 'body' : ''" #groupSelect>
      <ng-template ng-label-tmp let-item="item"></ng-template>
      <ng-option *ngFor="let group of groups" [value]="group" [disabled]="isGroupSelected(+group['idUserGroup'])">
        <div class="flex min-w-0 gap-2 justify-between items-center">
          <div class="flex flex-col min-w-0">
            <span class="truncate font-medium">{{group.label}}</span>
            <span class="truncate text-slate-600 text-sm">{{group['description']}}</span>
          </div>
          <div class="flex items-center gap-1.5 text-slate-600">
            <i class="fa-solid fa-user-group text-[0.875rem]"></i>
            <span class="text-sm">{{group['members'].length}}</span>
          </div>
        </div>
      </ng-option>
    </ng-select>
    <ng-select *ngIf="selectedType == 'all'" [multiple]="true" [clearable]="false" (change)="onMemberChange($event)"
      placeholder="Selecciona miembros de tu despacho" class="ng-select-override"
      [appendTo]="dialogData && dialogRef ? 'body' : ''" #memberSelect>
      <ng-template ng-label-tmp let-item="item"></ng-template>
      <ng-option *ngFor="let member of members" [value]="member" [disabled]="isSelected(+member.idMembersWorkspace)">
        <div class="flex items-center min-w-0 gap-2">
          <img class="pointer-events-none size-10 shrink-0 shadow-inner border-2 border-white rounded-full"
            [src]="member.selfie || '/assets/images/profile-placeholder.png'" alt="Foto de Perfil" />
          <span class="truncate">{{member.label}}</span>
        </div>
      </ng-option>
    </ng-select>
    <div class="flex items-center md:justify-end" *ngIf="selectedType == 'groups'">
      <button id="add-group-button"
        class="p-2 btn bg-white border-slate-200 hover:border-slate-300 hover:text-primary-100 text-sm gap-1.5 text-slate-600"
        (click)="createGroup()" ngxTippy="Nuevo Grupo">
        <i class="fa-solid fa-plus text-[1rem]"></i>
        <span>Nuevo Grupo</span>
      </button>
    </div>
  </div>

  <div class="max-w-full overflow-x-auto" id="members-list-container">
    <table class="min-w-full mt-2" *ngIf="selectedMembers[0] && selectedType == 'all'">
      <thead>
        <tr class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
          <th class="px-3 py-3 whitespace-nowrap text-left">Miembro</th>
          <th class="px-3 py-3 whitespace-nowrap">Acceso</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let member of selectedMembers; let i = index" class="*:px-3 *:py-3 *:whitespace-nowrap"
          [ngClass]="{ 'bg-gray-100': member.isCreator || member.fromGroup }">
          <td>
            <div class="flex items-center min-w-0 gap-2">
              <div class="relative">
                <img class="pointer-events-none size-10 shrink-0 shadow-inner border-2 border-white rounded-full"
                  [src]="member.selfie || '/assets/images/profile-placeholder.png'" alt="Foto de Perfil" />
                <div *ngIf="member.isCreator || member.fromGroup"
                  class="size-6 grid place-content-center bg-white text-slate-600 absolute -bottom-1 -right-1 rounded-full">
                  <i class="fa-solid fa-lock-keyhole text-[0.9rem]"
                    [ngxTippy]="member.isCreator ? 'No puedes editar tu propio nivel de acceso' : 'Para editar el acceso de este usuario, debes eliminar el grupo y editar el usuario individualmente'"></i>
                </div>
              </div>

              <div class="flex flex-col truncate min-w-0">
                <span class="truncate">{{member.label}}</span>
                <div *ngIf="member.fromGroup || member.canEditAll" class="flex gap-1.5 items-center flex-wrap">
                  <span *ngIf="member.canEditAll" ngxTippy="Este usuario puede editar todos los asuntos"
                    class="px-1.5 py-0.5 rounded-full bg-indigo-100 border border-indigo-200 text-indigo-600 text-xs">
                    Editar Todos
                  </span>
                  <span *ngFor="let group of getMemberGroups(member)"
                    [ngxTippy]="'Con acceso al asunto a través del grupo ' + group"
                    class="px-1.5 py-0.5 rounded-full bg-sky-100 border border-sky-200 text-sky-600 text-xs">
                    {{ group }}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td [id]="'access-control-' + i">
            <div class="flex flex-wrap gap-4 md:gap-8 justify-center">
              <label class="flex items-center gap-2 text-sm text-slate-600 font-medium">
                <input type="checkbox" [(ngModel)]="member.access.read" (ngModelChange)="onReadChange(member, $event)"
                  [disabled]="member.isCreator || member.fromGroup" class="form-checkbox" />
                Lectura
              </label>
              <label class="flex items-center gap-2 text-sm text-slate-600 font-medium">
                <input type="checkbox" [(ngModel)]="member.access.write"
                  [disabled]="member.isCreator || member.fromGroup || member.canEditAll" class="form-checkbox" />
                Escritura
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="min-w-full mt-2" *ngIf="selectedGroups[0] && selectedType == 'groups'">
      <thead>
        <tr class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
          <th class="px-3 py-3 whitespace-nowrap text-left">Grupo</th>
          <th class="px-3 py-3 whitespace-nowrap">Acceso</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let group of selectedGroups">
          <tr class="*:px-3 *:py-3 *:whitespace-nowrap">
            <td>
              <div class="flex items-center min-w-0 gap-2">
                <button class="size-8 text-slate-600 hover:text-indigo-600"
                  (click)="group.open ? group.open = false : group.open = true">
                  <i class="fa-solid fa-chevron-right text-[1rem]" [ngClass]="{ 'rotate-90': group.open }"></i>
                </button>
                <span class="truncate">{{group.label}}</span>
              </div>
            </td>
            <td>
              <div class="flex flex-wrap gap-4 md:gap-8 justify-center">
                <label class="flex items-center gap-2 text-sm text-slate-600 font-medium">
                  <input type="checkbox" [(ngModel)]="group.access.read"
                    (ngModelChange)="onGroupReadChange(group, $event)" class="form-checkbox" />
                  Lectura
                </label>
                <label class="flex items-center gap-2 text-sm text-slate-600 font-medium">
                  <input type="checkbox" [(ngModel)]="group.access.write" (ngModelChange)="onGroupWriteChange(group)"
                    class="form-checkbox" />
                  Escritura
                </label>
              </div>
            </td>
          </tr>
          <tr *ngIf="group.open" class="bg-slate-100 border-b border-slate-200">
            <td colspan="2" class="p-5 space-y-3">
              <div class="flex items-center min-w-0 gap-2" *ngFor="let member of group.members">
                <img class="pointer-events-none size-10 shrink-0 shadow-inner border-2 border-white rounded-full"
                  [src]="member.selfie || '/assets/images/profile-placeholder.png'" alt="Foto de Perfil" />
                <span class="truncate">{{member.fullName}}</span>
              </div>
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2"
  *ngIf="dialogRef">
  <button class="uisr-secondary-button" (click)="dialogRef.close()">
    <span translate="CANCEL_STRING"></span>
  </button>
  <button class="uisr-primary-button" (click)="save()">
    <span translate="CARD_SAVE"></span>
  </button>
</div>